import styles from "../../styles/partials/_nav.scss";
import { _class } from "../utilities/helpers";
import Link from "../widgets/Link";
import Transition from "../widgets/Transition";
import HeaderSelect from "./HeaderSelect";
import * as SVG from "../widgets/SVG";
import { connect } from "react-redux";
import { setCurrency, setLang } from "../redux/global/GlobalActions";
import lc_monogram from "../../images/lc_monogram.png";
import Image from "../widgets/Image";

const cl = _class(styles, "nav_mobile");

class MobileNav extends React.Component {
  static propTypes = {
    CONTENT: PropTypes.object,
    items: PropTypes.array,
    open: PropTypes.bool,
    currencies: PropTypes.array,
    languages: PropTypes.array,
    setLang: PropTypes.func,
    lang: PropTypes.string,
    currency: PropTypes.object,
    setCurrency: PropTypes.func,
    options: PropTypes.object,
    close: PropTypes.function,
  };

  static defaultProps = {
    items: [],
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeId: null,
      subNavOpen: false,
    };
  }

  setActive = (page) => this.setState({ activeId: page.id, subNavOpen: true });

  close = () => this.setState({ activeId: null, subNavOpen: false });

  closeMenu = () => {
    this.setState({ activeId: null, subNavOpen: false });
    this.props.close();
  };

  renderLC = () => (
    <Transition
      visible={this.props.open}
      properties={{
        x: ["-50%", "0%"],
        opacity: [0, 1],
        autoAlpha: [0, 1],
      }}
      delay={this.props.open ? 2000 : 0}
    >
      <div>
        <Link
          className={cl("lc_link")}
          path={"https://www.lascatalinascr.com/"}
        >
          <Image src={lc_monogram} alt={"lc_monogram"} />
        </Link>
      </div>
    </Transition>
  );

  renderContact = () => (
    <Transition
      visible={this.props.open}
      properties={{
        y: ["-50%", "0%"],
        opacity: [0, 1],
        autoAlpha: [0, 1],
      }}
      delay={this.props.open ? 1000 : 0}
    >
      <div><p className={cl("reservation")}>For Reservations:</p></div>
      <div className={cl("contact")}>
        <div className={cl("contact__icon")}>{SVG.phone}</div>
        <div className={cl("contact__numbers")}>
          <Link
            path={`tel:${this.props.options.h1}`}
            title={`US Toll Free ${this.props.options.h1}`}
          />
          <Link
            path={`tel:${this.props.options.h2}`}
            title={`Costa Rica ${this.props.options.h2}`}
          />
        </div>
      </div>
    </Transition>
  );

  renderChildren = (pages, visible) => {
    const path = window.location.pathname;
    return (
      <Transition
        appear
        visible={visible}
        properties={{
          height: [0, "auto"],
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
      >
        <ul className={`${cl("sublist")}`}>
          {pages.map((page) => {
            const match = path.match(new RegExp(`^${page.path}`));
            const activeStyle = match ? styles.active : "";

            return (
              <li
                key={page.id}
                className={`${cl("sublist__item")} ${activeStyle}`}
              >
                <div>
                  <Link
                    path={page.path}
                    title={page.linktitle}
                    onClick={this.closeMenu}
                    className={cl("sublist__item__link")}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </Transition>
    );
  };

  renderItems = () => {
    const { activeId } = this.state;
    const path = window.location.pathname;

    return (
      <Transition
        visible={this.props.open}
        mode={"stagger"}
        staggerClassName={cl("list__item")}
        properties={{
          x: ["-50%", "0%"],
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
        delay={this.props.open ? 1000 : 0}
      >
        <ul className={`${cl("list")} ${styles.mobile}`}>
          {this.props.items.map((page) => {
            const children = this.props.CONTENT.childrenById(page.id);
            const match = path.match(new RegExp(`^${page.path}`));
            const activeStyle = match ? styles.active : "";
            const openStyle = page.id === activeId ? styles.open : "";
            const childrenVisible = !!children.length && page.id === activeId;

            return (
              <li
                key={page.id}
                className={`${cl("list__item")} ${
                  styles.mobile
                } ${activeStyle} ${openStyle}`}
              >
                <div>
                  <Link
                    path={page.path}
                    delayLink={activeId === page.id}
                    title={page.linktitle}
                    onClick={(e) => {
                      if (children && children.length && page.id !== activeId) {
                        e.preventDefault();
                        this.setActive(page);
                      } else {
                        this.closeMenu();
                      }
                    }}
                    className={cl("list__item__link")}
                  />
                  {this.renderChildren(children, childrenVisible)}
                </div>
              </li>
            );
          })}
        </ul>
      </Transition>
    );
  };

  renderSelect = () => {
    const {
      currencies,
      languages,
      open,
      setCurrency,
      currency,
      setLang,
      lang,
    } = this.props;

    return (
      <Transition
        visible={open}
        mode={"stagger"}
        staggerClassName={"nav_mobile__select"}
        properties={{
          x: ["-50%", "0%"],
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
        delay={open ? 1500 : 0}
      >
        <div className={cl("select")}>
          <HeaderSelect
            options={currencies}
            value={{ label: currency.code.toUpperCase(), value: currency.code }}
            onSelect={(selected) => setCurrency(selected.value)}
            className={`${cl("options__currency")} nav_mobile__select`}
          />
          <HeaderSelect
            options={languages}
            value={{ label: lang.toUpperCase(), value: lang }}
            onSelect={(selected) => setLang(selected.value)}
            className={`${cl("options__lang")} nav_mobile__select`}
          />
        </div>
      </Transition>
    );
  };

  render() {
    return (
      <nav className={cl("")} ref>
        <Transition
          visible={this.props.open}
          properties={{
            y: ["-100%", "0%"],
            opacity: [0, 1],
            autoAlpha: [0, 1],
          }}
          duration={1000}
          delay={this.props.open ? 0 : 500}
        >
          <div className={`${cl("scrim")}`}>
            <div className={`${cl("slider")}`}>
              {this.renderContact()}
              {this.renderItems()}
              {this.renderSelect()}

              {this.renderLC()}
            </div>
          </div>
        </Transition>
      </nav>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  currency: global.currency,
  lang: global.lang,
});

export default connect(mapStateToProps, { setCurrency, setLang })(MobileNav);
