import styles from "../../styles/partials/_header.scss";
import { _class, parse, gtagTrackEvent } from "../utilities/helpers";
import Nav from "./Nav";
import Link from "../widgets/Link";
import { logo } from "../widgets/SVG";
import zenscroll from "zenscroll";
import CTA from "./CTA";
import * as SVG from "../widgets/SVG";
import Hamburger from "./Hamburger";
import SearchForm from "./SearchForm";
import HeaderSelect from "./HeaderSelect";
import MobileNav from "./MobileNav";
import { connect } from "react-redux";
import { setCurrency, setLang } from "../redux/global/GlobalActions";
import { currencies } from "../config/appConfig";
import { Translation } from "../utilities/Translation";
import lc_monogram from "../../images/lc_monogram.png";
import Image from "../widgets/Image";
import { withRouter } from "react-router-dom";

const cl = _class(styles, "header");

class Header extends React.Component {
  static propTypes = {
    CONTENT: PropTypes.object,
    lang: PropTypes.string,
    setLang: PropTypes.func,
    currency: PropTypes.object,
    setCurrency: PropTypes.func,
    languages: PropTypes.array,
    location: PropTypes.object,
    toggleBookingModal: PropTypes.func,
    viewport: PropTypes.object,
    history: PropTypes.object,
  };

  static contextTypes = {
    accessible: PropTypes.bool,
    history: PropTypes.object,
    filterByLang: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.languages = props.languages.map((lang) => ({
      value: lang,
      label: lang.toUpperCase(),
    }));

    this.currencies = currencies.map((currency) => ({
      value: currency.code,
      label: `${currency.symbol}${currency.code.toUpperCase()}`,
    }));

    this.options = props.CONTENT.byTemplate("options")[0];
    this.headerNav = this.options.fieldgroup1 || [];
    this.navItems = this.generateNavItems();
    this.bookingPage = props.CONTENT.byTemplate("checkout").find(
      context.filterByLang
    );

    this.state = {
      scrolled: false,
      searchOpen: false,
      navOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.offsetY);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.offsetY);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.search !== this.props.location.search &&
      this.state.searchOpen
    ) {
      this.setState({ searchOpen: false });
    }

    if (prevProps.lang !== this.props.lang) {
      this.LANG = new Translation(this.props.lang);
    }
  }

  generateNavItems = () => {
    const esChildren = this.props.CONTENT.childrenById(4);
    const ids = this.headerNav.map((item) => {
      const parsed = parse(item.buttonlink1);
      return parsed.link;
    });

    const esPages = esChildren.filter((item) => {
      const match = ids.find((id) => id === parseInt(item.canonical_ref));
      return match && item;
    });

    const enPages = this.headerNav.map((item) => {
      const parsed = parse(item.buttonlink1);
      return this.props.CONTENT.byId(parsed.link);
    });

    return {
      en: enPages,
      es: esPages,
    };
  };

  offsetY = () => this.setState({ scrolled: zenscroll.getY() > 70 });

  toggleSearch = () => this.setState({ searchOpen: !this.state.searchOpen });

  toggleNav = () => this.setState({ navOpen: !this.state.navOpen });

  renderContact = () => (
    <div className={cl("contact")}>
      <div className={cl("contact__icon")}>{SVG.phone}</div>
      <div className={cl("contact__numbers")}>
        <Link
          path={`tel:${this.options.h1}`}
          // title={this.LANG("orgPhoneNumber")}
          title={`US Toll Free ${this.options.h1}`}
          onClick={() =>
            gtagTrackEvent("AW-817146118/lT1cCI7on-4BEIbS0oUD", {
              phone_conversion_number: this.options.h1,
            })
          }
        />
        <Link
          path={`tel:${this.options.h2}`}
          title={`Costa Rica ${this.options.h2}`}
        />
      </div>
    </div>
  );

  renderLogo = () => (
    <Link
      path={`/${this.props.lang == "es" ? "es" : ""}`}
      className={cl("logo")}
    >
      {logo}
    </Link>
  );

  renderLC = () => (
    <Link className={cl("options__lc_link")} path={this.options.h6}>
      <Image src={lc_monogram} alt={"lc_monogram"} />
    </Link>
  );
  handleLanguageChange = (selected) => {
    const { setLang, history, location, CONTENT } = this.props;
    const newLang = selected.value;
    const currentPath = location.pathname;

    setLang(newLang);

    // Find the current page
    let currentPage = CONTENT.byPath(currentPath);

    if (!currentPage || currentPage.id === 1) {
      currentPage = CONTENT.byId(3); // Default to main page if root or page not found
    }

    // Find the corresponding page in the other language
    let otherLangPage = CONTENT.byId(
      currentPage.related || currentPage.canonical_ref
    );

    if (!otherLangPage) {
      // If no related page is found, try to find a page with a matching path in the other language
      const otherLangPath =
        newLang === "es"
          ? "/es" + currentPath
          : currentPath.replace(/^\/es/, "");

      otherLangPage = CONTENT.byPath(otherLangPath);
    }

    if (otherLangPage && otherLangPage.path) {
      // Redirect to the corresponding page in the new language
      history.push(otherLangPage.path + location.search);
    } else {
      // Fallback: redirect to home page of the new language
      history.push(newLang === "es" ? "/es" : "/");
    }
  };

  renderOptions = () => {
    const { lang } = this.props;
    return (
      <div className={cl("options")}>
        {this.renderSearch()}
        <HeaderSelect
          options={this.languages}
          value={{ label: lang.toUpperCase(), value: lang }}
          onSelect={this.handleLanguageChange}
          className={cl("options__lang")}
        />
        <CTA
          text={this.LANG("bookNow")}
          onClick={this.props.toggleBookingModal}
          //Temporary booking url fix
          // link={
          //   "https://be.synxis.com/?adult=2&chain=17718&child=0&config=Systemdefault&currency=USD&hotel=64537&level=hotel&locale=en-US&rooms=1&theme=Systemdefault"
          // }
          type="book"
        />
        {this.renderLC()}
      </div>
    );
  };

  renderSearch = () => (
    <div className={`${cl("options__search")} ${this.props.lang}`}>
      <div
        className={`${cl("options__icon")} ${
          this.state.searchOpen ? styles.active : ""
        }`}
        onClick={this.toggleSearch}
      >
        {SVG.search}
      </div>

      <div className={cl("options__search__form")}>
        <SearchForm open={this.state.searchOpen} CONTENT={this.props.CONTENT} />
      </div>
    </div>
  );

  renderMenuIcon = () => (
    <div className={`${cl("menu_icon")}`}>
      <Hamburger
        color="white"
        onClick={this.toggleNav}
        open={this.state.navOpen}
      />
    </div>
  );

  renderMobileBooking = () => (
    <div className={`${cl("mobile_booking")} ${this.props.lang}`}>
      <CTA
        text={this.LANG("book")}
        onClick={this.props.toggleBookingModal}
        //Temporary booking url fix
        // link={
        //   "https://be.synxis.com/?adult=2&chain=17718&child=0&config=Systemdefault&currency=USD&hotel=64537&level=hotel&locale=en-US&rooms=1&theme=Systemdefault"
        // }
        type="book"
        size="small"
      />
    </div>
  );

  renderNav = () => {
    return (
      <div className={`${cl("nav")}`}>
        {!this.context.accessible && this.props.viewport.is("tablet") ? (
          <MobileNav
            CONTENT={this.props.CONTENT}
            items={this.navItems[this.props.lang]}
            open={this.state.navOpen}
            close={this.toggleNav}
            options={this.options}
            languages={this.languages}
            currencies={this.currencies}
          />
        ) : (
          <Nav
            CONTENT={this.props.CONTENT}
            items={this.navItems[this.props.lang]}
          />
        )}
      </div>
    );
  };

  renderBooking = (booking) =>
    booking ? <div className={cl("spacer")} /> : this.renderOptions();

  render() {
    const { scrolled } = this.state;
    const path = window.location.pathname;
    const scrolledClass = scrolled ? styles.scrolled : "";
    const isBooking = this.bookingPage && path.match(this.bookingPage.path);

    return (
      <header className={`${cl("")} ${scrolledClass}`} id="header">
        <div className={`${cl("container")}`}>
          <div className={`${cl("top")}`}>
            {this.renderMenuIcon()}
            <div className={`${cl("contact_container")}`}>
              {!this.props.viewport.is("tablet") && <p>For Reservations:</p>}
              {this.renderContact()}
            </div>
            {this.renderLogo()}
            {this.renderBooking(isBooking)}
            {this.props.viewport.is("tablet") && this.renderSearch()}
            {this.renderMobileBooking()}
          </div>
        </div>
        {!isBooking && this.renderNav()}
      </header>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  currency: global.currency,
  lang: global.lang,
  languages: global.languages,
});

export default withRouter(
  connect(mapStateToProps, { setCurrency, setLang })(Header)
);
